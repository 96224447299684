import axios from "axios";
import { USERS_ACTIONS } from "../constants/action-types";
import { message } from "antd";

export const getAllUsers = (groupIds, companyId, activeOnly) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USERS_LOADING });
    await axios
      .get("/2.0.0/users", {
        //headers: { Authorization: token },
        params: {
          groupIds: groupIds,
          companyId: companyId,
          activeOnly: activeOnly,
        },
      })
      .then((response) => {
        dispatch({ type: USERS_ACTIONS.USERS_LOADED, payload: response.data });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.USERS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllUsersOffice = (companyId, activeOnly) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USERS_LOADING });
    await axios
      .get("/2.0.0/users/office", {
        params: {
          companyId: companyId,
          activeOnly: activeOnly,
        },
      })
      .then((response) => {
        dispatch({ type: USERS_ACTIONS.USERS_LOADED, payload: response.data });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.USERS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllWorkingUsers = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.WORKING_USERS_LOADING });
    await axios
      .get("/2.0.0/reception/working-users", {
        //headers: { Authorization: token },
        params: { companyId: companyId },
      })
      .then((response) => {
        dispatch({
          type: USERS_ACTIONS.WORKING_USERS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.WORKING_USERS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getUserWorklog = (userId) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.WORKLOG_LOADING });
    await axios
      .get("/1.0.0/reception/worklogs", userId, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: USERS_ACTIONS.WORKLOG_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.WORKLOG_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllGroups = () => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.GROUPS_LOADING });
    await axios
      .get("/1.0.0/groups", {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({ type: USERS_ACTIONS.GROUPS_LOADED, payload: response.data });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.GROUPS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const registerUser = (user) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USER_REGISTER_START });
    await axios
      .post("/1.0.0/users/registration", user, {
        //headers: { Authorization: token },
      })
      .then(() => {
        dispatch({ type: USERS_ACTIONS.USER_REGISTER_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.USER_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const modifyProfileUser = (formData) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USER_REGISTER_START });
    await axios
      .post("/1.0.0/users/modify-user-profile", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        dispatch({ type: USERS_ACTIONS.USER_REGISTER_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.USER_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const modifyUser = (user) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USER_REGISTER_START });
    await axios
      .post("/1.0.0/users/modify-user", user, {
        //headers: { Authorization: token },
      })
      .then(() => {
        dispatch({ type: USERS_ACTIONS.USER_REGISTER_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.USER_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};
export const modifyUserWizard = (user) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USER_REGISTER_START });
    await axios
      .post("/2.0.0/users/modify-user-wizard", user, {
        //headers: { Authorization: token },
      })
      .then(() => {
        dispatch({ type: USERS_ACTIONS.USER_REGISTER_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.USER_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const deleteProfilePic = (userId) => {
  return async () => {
    await axios.delete("/1.0.0/users/picture/" + userId).catch((err) => {
      console.log(err.message);
    });
  };
};

export const deleteUser = (userId) => {
  return async () => {
    await axios.delete("/1.0.0/users/" + userId).catch((err) => {
      console.log(err.message);
    });
  };
};
export const deleteUserByEmail = (email) => {
  return async () => {
    await axios
      .delete("/1.0.0/users/email", { params: { email: email } })
      .catch((err) => {
        console.log(err.message);
      });
  };
};

export const activateUser = (userId) => {
  return async () => {
    await axios
      .get("/1.0.0/users/activate", { params: { userId: userId } })
      .catch((err) => {
        message.error(
          "Nem aktiválható a felhasználó, mert elérte az előfizetésben foglalt felső határt!",
          10
        );
        console.log(err.message);
      });
  };
};

export const activateUsersInBatch = (userIds) => {
  return async () => {
    await axios.post("/1.0.0/users/activate", { userIds }).catch((err) => {
      message.error(
        "Nem aktiválható a felhasználó, mert elérte az előfizetésben foglalt felső határt!",
        10
      );
      console.log(err.message);
    });
  };
};

export const deactivateUsersInBatch = (userIds) => {
  return async () => {
    await axios.post("/1.0.0/users/deactivate", { userIds }).catch((err) => {
      console.log(err.message);
    });
  };
};

export const addGroupToUser = (userId, groupId, callback) => {
  return async () => {
    await axios
      .get("/1.0.0/users/addgroup", {
        params: { userId: userId, groupId: groupId },
      })
      .then(() => callback())
      .catch((err) => {
        message.error("Hiba a jogkör hozzáadása közben!", 5);
        console.log(err.message);
      });
  };
};

export const removeGroupFromUser = (userId, groupId, callback) => {
  return async () => {
    await axios
      .get("/1.0.0/users/removegroup", {
        params: { userId: userId, groupId: groupId },
      })
      .then(() => callback())
      .catch((err) => {
        message.error("Hiba a jogkör elvétele közben!", 5);
        console.log(err.message);
      });
  };
};

export const saveImportedUsers = (saveObject) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USER_REGISTER_START });
    await axios
      .post("/1.0.0/users/import", saveObject)
      .then((response) => {
        dispatch({ type: USERS_ACTIONS.USER_REGISTER_SUCCESS });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: USERS_ACTIONS.USER_REGISTER_ERROR,
          payload: err.response?.data,
        });
      });
  };
};

export const saveInvitedUsers = (saveObject) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USER_REGISTER_START });
    await axios
      .post("/1.0.0/users/invite", saveObject)
      .then((response) => {
        dispatch({ type: USERS_ACTIONS.USER_REGISTER_SUCCESS });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: USERS_ACTIONS.USER_REGISTER_ERROR,
          payload: err.response?.data,
        });
      });
  };
};

export const addImportErrors = (errors) => {
  return (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USER_IMPORT_ERRORS_ADD, payload: errors });
  };
};

export const emptyImportErrors = () => {
  return (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USER_IMPORT_ERRORS_EMPTY });
  };
};

export const checkIfEmailExists = (email) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USERS_EMAIL_EXISTS_LOADING });
    await axios
      .get("/2.0.0/users/email-exists", {
        params: { email: email },
      })
      .then((response) => {
        dispatch({
          type: USERS_ACTIONS.USERS_EMAIL_EXISTS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.USERS_EMAIL_EXISTS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllHosts = (companyId = undefined) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.HOSTS_LOADING });
    await axios
      .get("/2.0.0/users/hosts", { params: { companyId: companyId } })
      .then((response) => {
        dispatch({ type: USERS_ACTIONS.HOSTS_LOADED, payload: response.data });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.HOSTS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllColleagues = (companyId = undefined) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.COLLEAGUES_LOADING });
    await axios
      .get("/2.0.0/users/colleagues", { params: { companyId: companyId } })
      .then((response) => {
        dispatch({ type: USERS_ACTIONS.COLLEAGUES_LOADED, payload: response.data });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.COLLEAGUES_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getUsersForRegapps = (name, token, controllerAddress) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USERS_LOADING });
    await axios
      .get("/2.0.0/users/users-for-regapp", {
        //headers: { Authorization: token },
        params: {
          name: name,
          token: token,
          controllerAddress: controllerAddress,
        },
      })
      .then((response) => {
        dispatch({ type: USERS_ACTIONS.USERS_LOADED, payload: response.data });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.USERS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllUsersByOffice = (officeId) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USERS_BY_OFFICE_LOADING });
    await axios
      .get("/2.0.0/users/users-by-officeid/" + officeId, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: USERS_ACTIONS.USERS_BY_OFFICE_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.USERS_BY_OFFICE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const addOrRemoveCompanyAdminRole = (userId, isCompanyAdmin) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USERS_ADD_OR_REMOVE_COMPANY_ADMIN_ROLE_LOADING });
    await axios
      .put("/3.0.0/groups/add-or-remove/company-admin", { userId, isCompanyAdmin }, {
      })
      .then(() => {
        dispatch({ type: USERS_ACTIONS.USERS_ADD_OR_REMOVE_COMPANY_ADMIN_ROLE_LOADED });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.USERS_ADD_OR_REMOVE_COMPANY_ADMIN_ROLE_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const postUserConfig = (userId, saveObject) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USER_CONFIG_LOADING });
    await axios
      .post(`/3.0.0/users/user-config/${userId}`, saveObject)
      .then((response) => {
        dispatch({ type: USERS_ACTIONS.USUSER_CONFIG_LOADED });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: USERS_ACTIONS.USER_CONFIG_ERROR,
          payload: err.response?.data,
        });
      });
  };
};

