import React, { Component } from "react";
import Body from "./body";
import { Layout, Menu, Button, Select, Tag } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
  ControlOutlined,
  MessageOutlined,
  ExclamationCircleOutlined,
  BankOutlined,
  DollarCircleOutlined,
  RobotOutlined,
  ProfileOutlined,
  SettingOutlined,
  LaptopOutlined,
  CarOutlined,
  TeamOutlined,
  ReadOutlined,
  NotificationOutlined,
  ApartmentOutlined,
  HomeOutlined,
  FileDoneOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import "./main.scss";
import { connect } from "react-redux";
import { logout } from "../../actions/login";
import { NavLink } from "react-router-dom";
import HttpService from "../../services/http";
import socketIOClient from "socket.io-client";
import Modal from "antd/lib/modal/Modal";
import {
  deactivateEmergency,
  activateEmergency,
} from "../../actions/emergency";
import logo from "../../static/logo3.png";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import md5 from "md5";
import { closeTag, openTagWithMessage } from "../../actions/tag";
import { subscriptionIdExceptions } from "../../constants/subscriptionIdExceptions";
import { userRoles } from "../../constants/roles-types";
const { Header, Sider, Content } = Layout;

const { SubMenu } = Menu;

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    deactivateEmergency: () => dispatch(deactivateEmergency()),
    activateEmergency: () => dispatch(activateEmergency()),
    closeTag: () => dispatch(closeTag()),
    openTagWithMessage: (messageToSend) =>
      dispatch(openTagWithMessage(messageToSend)),
  };
};

class ConnectedMain extends Component {
  state = {
    collapsed: false,
    selectedKey: "1",
    socket: null,
    snapped: true,
    height: window.innerHeight,
    width: window.innerWidth,
    openKeys: [],
  };

  constructor(props) {
    super(props);
    HttpService.setAxiosDefaults(props.token);
  }

  componentDidMount = async () => {
    this.setDefaultSelectedKeyForMenu();
    const socket = socketIOClient(`${process.env.REACT_APP_BASE_URL}`, {
      transports: ["websocket"],
      query: { token: this.props.token.split(" ")[1] },
      path: "/api/socket.io",
      secure: true,
    });
    this.setState({ socket: socket }); //socket mentése
    socket.on("emergencyActivateClient", () => {
      this.props.activateEmergency();
    });
    socket.on("emergencyDeactivateClient", () => {
      this.props.deactivateEmergency();
    });
    socket.io.connect();
    if (this.props.user.company && this.props.user.company.emergency) {
      this.props.activateEmergency();
    } else {
      this.props.deactivateEmergency();
    }
    if (this.props.user.company !== null) {
      i18n.changeLanguage(
        this.props.user.company.defaultLanguage.code.toLowerCase()
      );
    }
    window.addEventListener("resize", () => {
      this.setSnapLogoToMenuEnd();
    });
    window.addEventListener("change", () => {
      this.setSnapLogoToMenuEnd();
    });
    this.setSnapLogoToMenuEnd();
    if (this.props.apiKey && this.hasRight([userRoles.COMPANY_ADMIN])) {
      const md5hash = md5(
        `${this.props.user.companyId}${this.props.apiKey}${this.props.user.email}`
      );
      this.setState({ md5hash: md5hash });
    }
  };

  setSnapLogoToMenuEnd = () => {
    this.setState({ height: window.innerHeight, width: window.innerWidth });
    const menu = document.querySelector(".ant-menu");
    if (window.innerHeight - menu?.getBoundingClientRect().height <= 220) {
      this.setState({ snapped: false });
    } else {
      this.setState({ snapped: true });
    }
  };

  componentWillUnmount = () => {
    HttpService.clearToken();
    if (this.state.socket) {
      this.state.socket.disconnect();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setDefaultSelectedKeyForMenu();
    }
    if (prevState.openKeys.length !== this.state.openKeys.length) {
      this.setSnapLogoToMenuEnd();
    }
  }

  handleDeactivateEmergency = () => {
    this.state.socket.emit("emergencyDeactivate", {
      companyId: this.props.user.companyId,
      userId: this.props.user.id,
    });
  };

  handleChangeLanguage = (event) => {
    i18n.changeLanguage(event);
  };

  setDefaultSelectedKeyForMenu = () => {
    switch (this.props.location.pathname) {
      case "/tl/system-admins":
        this.props.closeTag();
        this.setState({ selectedKey: "1" });
        break;
      case "/tl/administration":
        this.props.closeTag();
        this.setState({ selectedKey: "4" });
        break;
      case "/tl/subscriptions":
        this.props.closeTag();
        this.setState({ selectedKey: "2" });
        break;
      case "/tl/companies":
        this.props.closeTag();
        this.setState({ selectedKey: "3" });
        break;
      case "/tl/messages":
        this.props.openTagWithMessage("messages-tip");
        this.setState({ selectedKey: "5" });
        break;
      case "/tl/custom-push-notification":
        this.props.closeTag();
        this.setState({ selectedKey: "87" });
        break;
      case "/tl/emergency":
        this.props.closeTag();
        this.setState({ selectedKey: "6" });
        break;
      case "/tl/company-management":
        this.props.closeTag();
        this.setState({ selectedKey: "7" });
        break;
      case "/tl/workers":
        this.props.openTagWithMessage("workers-users-tip");
        this.setState({ selectedKey: "8" });
        break;
      case "/tl/reception":
        this.props.openTagWithMessage("reception-invited-tip");
        this.setState({ selectedKey: "9" });
        break;
      case "/tl/reception-guests":
        this.props.openTagWithMessage("reception-invited-tip");
        this.setState({ selectedKey: "91" });
        break;
      case "/tl/help":
        this.props.closeTag();
        this.setState({ selectedKey: "10" });
        break;
      case "/tl/users":
        this.props.closeTag();
        this.setState({ selectedKey: "12" });
        break;
      case "/tl/company-admins":
        this.props.closeTag();
        this.setState({ selectedKey: "11" });
        break;
      case "/tl/profile":
        this.props.closeTag();
        this.setState({ selectedKey: "13" });
        break;
      case "/tl/reg-app-tokens":
        this.props.openTagWithMessage("regapps-tip");
        this.setState({ selectedKey: "15" });
        break;
      case "/tl/design":
        this.props.openTagWithMessage("design-tip");
        this.setState({ selectedKey: "16" });
        break;
      case "/tl/addresses":
        this.props.openTagWithMessage("companydata-tip");
        this.setState({ selectedKey: "17" });
        break;
      case "/tl/office-addresses":
        this.props.closeTag();
        this.setState({ selectedKey: "70" });
        break;
      case "/tl/agreements":
        this.props.openTagWithMessage("agreements-tip");
        this.setState({ selectedKey: "18" });
        break;
      case "/tl/language":
        this.props.closeTag();
        this.setState({ selectedKey: "19" });
        break;
      case "/tl/reception-admin":
        this.props.openTagWithMessage("workers-reception-tip");
        this.setState({ selectedKey: "20" });
        break;
      case "/tl/hr-admin":
        this.props.openTagWithMessage("workers-hr-tip");
        this.setState({ selectedKey: "21" });
        break;
      case "/tl/entrypoints":
        this.props.closeTag();
        this.setState({ selectedKey: "25" });
        break;
      case "/tl/working-users":
        this.props.closeTag();
        this.setState({ selectedKey: "26" });
        break;
      case "/tl/offices":
        this.props.closeTag();
        this.setState({ selectedKey: "27" });
        break;
      case "/tl/office-admins":
        this.props.closeTag();
        this.setState({ selectedKey: "28" });
        break;
      case "/tl/proximity-card":
        this.props.closeTag();
        this.setState({ selectedKey: "29" });
        break;
      case "/tl/vehicles":
        this.props.closeTag();
        this.setState({ selectedKey: "30" });
        break;
      case "/tl/parking-gates":
        this.props.closeTag();
        this.setState({ selectedKey: "31" });
        break;
      case "/tl/parking-park":
        this.props.closeTag();
        this.setState({ selectedKey: "32" });
        break;
      case "/tl/parking-groups":
        this.props.closeTag();
        this.setState({ selectedKey: "33" });
        break;
      case "/tl/manual-enter":
        this.props.closeTag();
        this.setState({ selectedKey: "34" });
        break;
      case "/tl/building/layout":
        this.props.closeTag();
        this.setState({ selectedKey: "9001" });
        break;
      default:
        this.props.closeTag();
        this.setState({ selectedKey: null });
        break;
    }
  };

  hasRight = (rights, notAllowedSubscriptions) => {
    let allowed = false;
    let parkingAllowed = false;
    this.props.user.groups.forEach((group) => {
      if (rights.includes(group.id)) {
        allowed = true;
      }
    });
    if (
      this.props.user.company?.officeId !== undefined &&
      this.props.user.company?.officeId !== null
    ) {
      parkingAllowed = true;
    } else if (
      this.props.user.officeId !== undefined &&
      this.props.user.officeId !== null
    ) {
      parkingAllowed = true;
    }
    if (
      parkingAllowed === false &&
      parkingAllowed === undefined &&
      parkingAllowed === null
    ) {
      return false;
    } else {
      if (allowed === true && notAllowedSubscriptions === undefined) {
        return true;
      } else {
        if (allowed === true) {
          if (this.allowedBySubscription(notAllowedSubscriptions)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    }
  };

  allowedBySubscription = (notAllowedSubscriptions) => {
    if (this.props.user.officeId) {
      return true;
    }
    if (this.props.user.companyId === null) {
      return false;
    } else {
      if (
        notAllowedSubscriptions !== undefined &&
        notAllowedSubscriptions !== null
      ) {
        if (
          notAllowedSubscriptions.includes(
            this.props.user.company.subscriptionId
          )
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  handleSelect = (event) => {
    this.setState({ selectedKey: event.key });
  };

  logout = async () => {
    await this.props.logout();
    this.props.history.push("/login");
  };

  handleMenuClick = async (event) => {
    switch (event.key) {
      case "logout":
        await this.logout();
        break;
      case "profile":
        this.props.history.push("/tl/profile");
        break;
      default:
        break;
    }
  };

  renderMenu = () => {
    return (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="profile" icon={<ProfileOutlined />}>
          {this.props.t("menu-profile-button-label")}
        </Menu.Item>
        <Menu.Item key="logout" icon={<LogoutOutlined />}>
          {this.props.t("menu-logout-button-label")}
        </Menu.Item>
      </Menu>
    );
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  openKeys = (event) => {
    if (this.state.openKeys.length === 0) {
      this.setState({ openKeys: event });
    } else {
      const notInculded = event.filter(
        (element) => element !== this.state.openKeys[0]
      );

      this.setState({ openKeys: notInculded });
    }
    setTimeout(() => {
      this.setSnapLogoToMenuEnd();
    }, 150);
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        <Layout style={{ height: "100vh" }}>
          <Sider
            trigger={null}
            collapsible
            collapsed={this.state.collapsed}
            className={!this.state.collapsed && "sider"}
            style={{
              background: "#151917",
            }}
          >
            <div
              className={
                this.state.collapsed
                  ? "logo-container logo-container-collapsed"
                  : "logo-container"
              }
            >
              {this.props.user.company && this.props.user.company.logoName ? (
                <img
                  src={`${
                    process.env.REACT_APP_BASE_URL
                  }/api/1.0.0/company/logo/${
                    this.props.user.company.logoName
                  }?token=${this.props.token.split(" ")[1]}`}
                  alt="company-logo"
                  className={
                    this.state.collapsed ? "logo-icon-collapsed" : "logo-icon"
                  }
                />
              ) : (
                <img
                  src={logo}
                  alt="tablog-logo"
                  className={
                    this.state.collapsed
                      ? "logo-icon-tablog-collapsed"
                      : "logo-icon-tablog"
                  }
                />
              )}
            </div>
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[this.state.selectedKey]}
              onSelect={this.handleSelect}
              style={{
                background: "#151917",
              }}
              openKeys={this.state.openKeys}
              onOpenChange={this.openKeys}
              subMenuOpenDelay={0}
              forceSubMenuRender
            >
              {this.hasRight([userRoles.SYSTEM_ADMIN]) && (
                <SubMenu
                  icon={<ControlOutlined />}
                  key="sub2"
                  title={this.props.t("menu-admin-user-label")}
                >
                  <Menu.Item key="2" icon={<DollarCircleOutlined />}>
                    <NavLink to="/tl/subscriptions" className="link-color">
                      {this.props.t("menu-subscriptions-button-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="3" icon={<BankOutlined />}>
                    <NavLink to="/tl/companies" className="link-color">
                      {this.props.t("menu-companies-button-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="27" icon={<LaptopOutlined />}>
                    <NavLink to="/tl/offices" className="link-color">
                      {this.props.t("menu-offices-button-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="87" icon={<NotificationOutlined />}>
                    <NavLink
                      to="/tl/custom-push-notification"
                      className="link-color"
                    >
                      {this.props.t(
                        "menu-custom-push-notification-button-label"
                      )}
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
              )}
              {this.hasRight([userRoles.SYSTEM_ADMIN]) && (
                <SubMenu
                  key="sub1"
                  title={this.props.t("menu-users-button-label")}
                  icon={<UserOutlined />}
                >
                  <Menu.Item key="1" icon={<UserOutlined />}>
                    <NavLink to="/tl/system-admins" className="link-color">
                      {this.props.t("menu-systemadmins-button-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="28" icon={<UserOutlined />}>
                    <NavLink to="/tl/office-admins" className="link-color">
                      {this.props.t("menu-officeadmins-button-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="12" icon={<UserOutlined />}>
                    <NavLink to="/tl/users" className="link-color">
                      {this.props.t("menu-users-button-label")}
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
              )}
              {this.hasRight(
                [userRoles.COMPANY_ADMIN, userRoles.RECEPTION_ADMIN],
                subscriptionIdExceptions.inviteExceptionIds
              ) && (
                <SubMenu
                  key="reception"
                  title={this.props.t("menu-reception-button-label")}
                  icon={<RobotOutlined />}
                >
                  <Menu.Item key="9" icon={<ReadOutlined />}>
                    <NavLink to="/tl/reception" className="link-color">
                      {this.props.t("menu-reception-button-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="91" icon={<TeamOutlined />}>
                    <NavLink to="/tl/reception-guests" className="link-color">
                      {this.props.t("menu-reception-guests-button-label")}
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
              )}
              {this.hasRight(
                [userRoles.INVITE_USER_RECEPTION_ADMIN],
                subscriptionIdExceptions.inviteExceptionIds
              ) && (
                <SubMenu
                  key="reception"
                  title={this.props.t("menu-reception-button-label")}
                  icon={<RobotOutlined />}
                >
                  <Menu.Item key="9" icon={<ReadOutlined />}>
                    <NavLink to="/tl/reception" className="link-color">
                      {this.props.t("menu-reception-button-label")}
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
              )}
              {this.hasRight([userRoles.COMPANY_ADMIN]) && (
                <SubMenu
                  key="settings"
                  title={this.props.t("companyadmin-title")}
                  icon={<SettingOutlined />}
                >
                  {!this.props.user.company?.officeId && (
                    <Menu.Item key="15">
                      <NavLink to="/tl/reg-app-tokens" className="link-color">
                        {this.props.t("companyadmin-tabstitle-regapps")}
                      </NavLink>
                    </Menu.Item>
                  )}
                  <Menu.Item key="16">
                    <NavLink to="/tl/design" className="link-color">
                      {this.props.t("companyadmin-tabstitle-design")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="29">
                    <NavLink to="/tl/proximity-card" className="link-color">
                      {this.props.t("companyadmin-tabstitle-proximity-card")}
                    </NavLink>
                  </Menu.Item>
                  {this.hasRight(
                    [userRoles.COMPANY_ADMIN, userRoles.RECEPTION_ADMIN],
                    [1, 4, 7]
                  ) &&
                    this.props.user.company.smartLock && (
                      <Menu.Item key="25">
                        <NavLink to="/tl/entrypoints" className="link-color">
                          {this.props.t("menu-entrypoint-button-label")}
                        </NavLink>
                      </Menu.Item>
                    )}
                  <Menu.Item key="17">
                    <NavLink to="/tl/addresses" className="link-color">
                      {this.props.t("companyadmin-tabstitle-address")}
                    </NavLink>
                  </Menu.Item>
                  {this.hasRight(
                    [userRoles.COMPANY_ADMIN],
                    subscriptionIdExceptions.inviteExceptionIds
                  ) && (
                    <Menu.Item key="18">
                      <NavLink to="/tl/agreements" className="link-color">
                        {this.props.t("companyadmin-tabstitle-agreements")}
                      </NavLink>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}
              {this.hasRight([
                userRoles.OFFICE_ADMIN,
                userRoles.OFFICE_RECEPTION_ADMIN,
              ]) && (
                <SubMenu
                  key="reception"
                  title={this.props.t("menu-reception-button-label")}
                  icon={<RobotOutlined />}
                >
                  <Menu.Item key="9" icon={<ReadOutlined />}>
                    <NavLink to="/tl/reception" className="link-color">
                      {this.props.t("menu-reception-button-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="91" icon={<TeamOutlined />}>
                    <NavLink to="/tl/reception-guests" className="link-color">
                      {this.props.t("menu-reception-guests-button-label")}
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
              )}
              {this.hasRight([userRoles.OFFICE_ADMIN]) && (
                <Menu.Item key="3" icon={<BankOutlined />}>
                  <NavLink to="/tl/companies" className="link-color">
                    {this.props.t("menu-companies-button-label")}
                  </NavLink>
                </Menu.Item>
              )}
              {this.hasRight([userRoles.DEVELOPER_USER]) && (
                <SubMenu
                  key="building-settings"
                  title={this.props.t("menu-building-settings-button-label")}
                  icon={<HomeOutlined />}
                >
                  <Menu.Item key="9001" icon={<ApartmentOutlined />}>
                    <NavLink to="/tl/building/layout" className="link-color">
                      {this.props.t(
                        "menu-building-settings-layout-button-label"
                      )}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="9002" icon={<FileDoneOutlined />}>
                    <NavLink to="/tl/building/rooms" className="link-color">
                      {this.props.t("menu-building-settings-rooms-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="9003" icon={<UsergroupAddOutlined />}>
                    <NavLink
                      to="/tl/building/permission/user"
                      className="link-color"
                    >
                      {this.props.t(
                        "menu-building-settings-permission-groups-label"
                      )}
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
              )}
              {this.hasRight([userRoles.OFFICE_ADMIN]) && (
                <Menu.Item key="28" icon={<UserOutlined />}>
                  <NavLink to="/tl/office-admins" className="link-color">
                    {this.props.t("menu-officeadmins-button-label")}
                  </NavLink>
                </Menu.Item>
              )}
              {this.hasRight([userRoles.OFFICE_ADMIN]) && (
                <Menu.Item key="299" icon={<UserOutlined />}>
                  <NavLink to="/tl/workers" className="link-color">
                    {this.props.t("menu-users-button-label")}
                  </NavLink>
                </Menu.Item>
              )}
              {this.hasRight([userRoles.OFFICE_ADMIN]) && (
                <Menu.Item key="6" icon={<ExclamationCircleOutlined />}>
                  <NavLink to="/tl/emergency" className="link-color">
                    {this.props.t("menu-emergency-button-label")}
                  </NavLink>
                </Menu.Item>
              )}
              {this.hasRight([userRoles.OFFICE_ADMIN]) && (
                <SubMenu
                  key="officeSettings"
                  title={this.props.t("companyadmin-title")}
                  icon={<SettingOutlined />}
                >
                  <Menu.Item key="15">
                    <NavLink to="/tl/reg-app-tokens" className="link-color">
                      {this.props.t("companyadmin-tabstitle-regapps")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="16">
                    <NavLink to="/tl/design" className="link-color">
                      {this.props.t("companyadmin-tabstitle-design")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="25">
                    <NavLink to="/tl/entrypoints" className="link-color">
                      {this.props.t("menu-entrypoint-button-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="70">
                    <NavLink to="/tl/office-addresses" className="link-color">
                      {this.props.t("office-menu-addresses-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="29">
                    <NavLink to="/tl/proximity-card" className="link-color">
                      {this.props.t("companyadmin-tabstitle-proximity-card")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="18">
                    <NavLink to="/tl/agreements" className="link-color">
                      {this.props.t("companyadmin-tabstitle-agreements")}
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
              )}
              {this.hasRight([userRoles.COMPANY_ADMIN]) ? (
                <SubMenu
                  key="workers"
                  title={this.props.t("menu-workers-button-label")}
                  icon={<UserOutlined />}
                >
                  <Menu.Item key="8" icon={<UserOutlined />}>
                    <NavLink to="/tl/workers" className="link-color">
                      {this.props.t("menu-users-button-label")}
                    </NavLink>
                  </Menu.Item>
                  {this.hasRight(
                    [userRoles.COMPANY_ADMIN],
                    subscriptionIdExceptions.inviteExceptionIds
                  ) && (
                    <Menu.Item key="20" icon={<UserOutlined />}>
                      <NavLink to="/tl/reception-admin" className="link-color">
                        {this.props.t("companyadmin-tabstitle-reception")}
                      </NavLink>
                    </Menu.Item>
                  )}
                  <Menu.Item key="21" icon={<UserOutlined />}>
                    <NavLink to="/tl/hr-admin" className="link-color">
                      {this.props.t("companyadmin-tabstitle-hr")}
                    </NavLink>
                  </Menu.Item>
                  {this.hasRight(
                    [userRoles.COMPANY_ADMIN, userRoles.HR_ADMIN],
                    subscriptionIdExceptions.workExceptionIds
                  ) && (
                    <Menu.Item key="26" icon={<UserOutlined />}>
                      <NavLink to="/tl/working-users" className="link-color">
                        {this.props.t("companyadmin-working-users")}
                      </NavLink>
                    </Menu.Item>
                  )}
                </SubMenu>
              ) : (
                this.hasRight([userRoles.HR_ADMIN]) && (
                  <Menu.Item key="8" icon={<UserOutlined />}>
                    <NavLink to="/tl/workers" className="link-color">
                      {this.props.t("menu-users-button-label")}
                    </NavLink>
                  </Menu.Item>
                )
              )}
              {this.hasRight(
                [
                  userRoles.SYSTEM_ADMIN,
                  userRoles.COMPANY_ADMIN,
                  userRoles.RECEPTION_ADMIN,
                  userRoles.HR_ADMIN,
                  userRoles.OFFICE_ADMIN,
                  userRoles.OFFICE_RECEPTION_ADMIN,
                ],
                subscriptionIdExceptions.workExceptionIds
              ) && (
                <Menu.Item key="5" icon={<MessageOutlined />}>
                  <NavLink to="/tl/messages" className="link-color">
                    {this.props.t("menu-messages-button-label")}
                  </NavLink>
                </Menu.Item>
              )}
              {this.hasRight(
                [userRoles.SYSTEM_ADMIN, userRoles.OFFICE_ADMIN],
                subscriptionIdExceptions.workExceptionIds
              ) && (
                <SubMenu
                  key="parkings"
                  title={this.props.t("park-label")}
                  icon={<CarOutlined />}
                >
                  <Menu.Item key="30">
                    <NavLink to="/tl/vehicles" className="link-color">
                      {this.props.t("menu-parking-button-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="31">
                    <NavLink to="/tl/parking-gates" className="link-color">
                      {this.props.t("menu-gates-button-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="32">
                    <NavLink to="/tl/parking-park" className="link-color">
                      {this.props.t("menu-park-button-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="33">
                    <NavLink to="/tl/parking-groups" className="link-color">
                      {this.props.t("parking-group-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="34">
                    <NavLink to="/tl/manual-enter" className="link-color">
                      {this.props.t("manual-enter-button-label")}
                    </NavLink>
                  </Menu.Item>
                  {this.hasRight(
                    [userRoles.OFFICE_ADMIN],
                    subscriptionIdExceptions.workExceptionIds
                  ) && (
                    <Menu.Item key="777">
                      <NavLink to="/tl/parking-export" className="link-color">
                        {this.props.t("export-button-label")}
                      </NavLink>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}
              {this.hasRight(
                [userRoles.COMPANY_ADMIN],
                subscriptionIdExceptions.workExceptionIds
              ) && (
                <SubMenu
                  key="parkings"
                  title={this.props.t("park-label")}
                  icon={<CarOutlined />}
                >
                  <Menu.Item key="30">
                    <NavLink to="/tl/vehicles" className="link-color">
                      {this.props.t("menu-parking-button-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="31">
                    <NavLink to="/tl/parking-gates" className="link-color">
                      {this.props.t("menu-gates-button-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="32">
                    <NavLink to="/tl/parking-park" className="link-color">
                      {this.props.t("menu-park-button-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="33">
                    <NavLink to="/tl/parking-groups" className="link-color">
                      {this.props.t("parking-group-label")}
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
              )}
              {this.hasRight([userRoles.PARKING_RECEPTION]) && (
                <SubMenu
                  key="parkings"
                  title={this.props.t("park-label")}
                  icon={<CarOutlined />}
                >
                  <Menu.Item key="31">
                    <NavLink to="/tl/parking-gates" className="link-color">
                      {this.props.t("menu-gates-button-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="34">
                    <NavLink to="/tl/manual-enter" className="link-color">
                      {this.props.t("manual-enter-button-label")}
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="30">
                    <NavLink to="/tl/vehicles" className="link-color">
                      {this.props.t("menu-parking-button-label")}
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
              )}
              {this.hasRight(
                [
                  userRoles.SYSTEM_ADMIN,
                  userRoles.COMPANY_ADMIN,
                  userRoles.RECEPTION_ADMIN,
                ],
                subscriptionIdExceptions.workExceptionIds
              ) && (
                <Menu.Item key="6" icon={<ExclamationCircleOutlined />}>
                  <NavLink to="/tl/emergency" className="link-color">
                    {this.props.t("menu-emergency-button-label")}
                  </NavLink>
                </Menu.Item>
              )}
              {this.hasRight([userRoles.COMPANY_ADMIN]) && (
                <Menu.Item key="22" icon={<DollarCircleOutlined />}>
                  <a
                    href={`${process.env.REACT_APP_REDIRECT}${this.state.md5hash}?subscription_id=${this.props.user.companyId}`}
                    className="link-color"
                  >
                    {this.props.t("subscription-menu-label")}
                  </a>
                </Menu.Item>
              )}

              <Menu.Item key="14" icon={<LogoutOutlined />}>
                <NavLink className="link-color" to="*" onClick={this.logout}>
                  {this.props.t("menu-logout-button-label")}
                </NavLink>
              </Menu.Item>

              {this.props.user.company && this.props.user.company.logoName && (
                <div
                  className={
                    this.state.collapsed
                      ? "logo-container logo-container-collapsed"
                      : "logo-container"
                  }
                  style={
                    this.state.snapped
                      ? {
                          bottom: this.state.collapsed ? "0.1%" : "2%",
                          left: this.state.collapsed ? "0px" : "0px",
                          position: "absolute",
                        }
                      : {}
                  }
                >
                  <img
                    src={logo}
                    alt="tablog-logo"
                    className={
                      this.state.collapsed ? "logo-icon-collapsed" : "logo-icon"
                    }
                  />
                </div>
              )}
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: "0 0 0 26px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>
                  {React.createElement(
                    this.state.collapsed
                      ? MenuUnfoldOutlined
                      : MenuFoldOutlined,
                    {
                      className: "trigger",
                      onClick: this.toggle,
                    }
                  )}
                </div>
                <div>
                  <Select
                    style={{ width: "130px" }}
                    value={i18n.language}
                    onChange={this.handleChangeLanguage}
                  >
                    <Select.Option key="hu" value="hu">
                      Magyar
                    </Select.Option>
                    <Select.Option key="en" value="en">
                      English
                    </Select.Option>
                    <Select.Option key="de" value="de">
                      Deutsch
                    </Select.Option>
                  </Select>
                </div>
              </div>
              <div
                style={{ width: "65vw", height: "auto", paddingTop: "15px" }}
              >
                <Tag
                  closable
                  onClose={this.props.closeTag}
                  visible={this.props.visible}
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#ADADAD",
                    fontSize: "1rem",
                    maxWidth: "100%",
                    whiteSpace: "unset",
                    position: "relative",
                    paddingRight: "25px",
                  }}
                >
                  {this.props.t(this.props.message)}
                </Tag>
              </div>
              <div style={{ float: "right" }}>
                <NavLink to="/tl/help">
                  <Button type="link">
                    {this.props.t("menu-help-button-label")}
                  </Button>
                </NavLink>
                <NavLink to="/tl/profile">
                  <Button
                    size="large"
                    icon={<UserOutlined />}
                    style={{
                      margin: "12px 26px 0 0",
                    }}
                  />
                </NavLink>
              </div>
            </Header>
            <Content
              className="site-layout-background"
              style={{
                margin: "24px 16px",
                padding: 24,
              }}
            >
              <Body socket={this.state.socket} />
            </Content>
          </Layout>
        </Layout>
        <Modal
          visible={this.props.emergency}
          title={this.props.t("emergency-popup-title")}
          closable={false}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={this.handleDeactivateEmergency}
            >
              {this.props.t("emergency-popup-button-label")}
            </Button>,
          ]}
          maskClosable={false}
        >
          {this.props.t("emergency-popup-title")}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  emergency: state.emergencyReducer.emergency,
  apiKey: state.loginReducer.apiKey,
  message: state.tagReducer.message,
  visible: state.tagReducer.visible,
  groups: state.usersReducer.groups,
});

const Main = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedMain)
);
export default Main;
